import React, {
  Component
} from 'react';

export default function effect_cube() {
    let constrain = 50;
    var scene = document.querySelector('.scene');
    var cube = document.querySelector('.cube');
    var radioGroup = document.querySelector('.radio-group');
    var currentClass = '';


    function changeSide() {
      var checkedRadio = radioGroup.querySelector(':checked');
      var showClass = 'show-' + checkedRadio.value;

      if ( currentClass ) {
         cube.classList.remove( currentClass );
       }
      cube.classList.add( showClass );
      currentClass = showClass;
    }
    // set initial side
    changeSide();
    radioGroup.addEventListener( 'change', changeSide );

}